// GA的 注册表单页面
import React, { useState, useMemo } from 'react'
import Layout from 'layouts/en'
import Seo from 'components/EN/Seo'
import Form from 'components/EN/GAForm'
import { FORM_DATA_LIST } from 'assets/js/constant.js'
import { notification } from 'sensd'
import { fetchGA4From } from 'api/user.js'
import 'sensd/dist/sensd.css'
import classnames from 'classnames'
import form_bg from 'assets/images/form/sa-gtm-setup/form_bg.png'
import * as styles from './index.module.less'
const LoadingSpinner = () => (
  <div>
    <div className={styles.loading} />
    <div className={styles.submitting}>Submitting...</div>
  </div>
)
const SuccessCheck = () => {
  return (
    <div className={styles.success}>
      <div className="success-icon" />
      <div className="success-title">Thank you for your interest in Sensors Data</div>
      <div className="success-desc">
        We'll reach out to you within 24 hours by email. Please check your email address then.
      </div>
    </div>
  )
}
const GA4From = () => {
  const [status, setStatus] = useState('init')
  const onSubmit = async (values) => {
    try {
      if (status === 'loading') return
      setStatus('loading')
      const res = await fetchGA4From(values)
      const { status: resStatus } = res
      if (resStatus === 200) {
        setStatus('success')
      } else {
        setStatus('error')
      }
    } catch (err) {
      setStatus('error')
      console.error(err)
    }
  }
  const renderCom = useMemo(() => {
    switch (status) {
      case 'init':
        return <Form onSubmit={onSubmit} />
      case 'loading':
        return <LoadingSpinner />
      case 'success':
        return <SuccessCheck />
      case 'error':
        notification['error']({
          message: 'Submission failed, please try again later.',
        })
        return <Form onSubmit={onSubmit} />
      default:
        return <Form onSubmit={onSubmit} />
    }
  }, [status])

  return (
    <Layout hideFooter>
      <Seo
        title="Getting Started - Set Up Sensors Analytics Using Google Tag Manager"
        description="Quickly set up Sensors Analytics(SA) using our Google Tag Manager Templates which allows you to easily implement SA’s website tracking code."
        keywords="Sensors Analytics, Sensors Data vs Google Analytics"
      />
      <main className={classnames(' lg:flex lg:justify-center lg:items-center', styles.root)}>
        <div className="lg:flex">
          <div className="lg:w-[500px] lg:flex lg:flex-col lg:justify-between">
            <h2 className="registration-title">Get started using Google Tag Manager</h2>
            <p className="registration-desc">
              Quickly set up Sensors Analytics(SA) using our Google Tag Manager Templates, which allows you to easily
              implement SA’s website tracking code. After sign-up, we will contact you to go through following steps for
              a free trail
            </p>
            <div className="registration-list">
              {FORM_DATA_LIST.map((item) => {
                return (
                  <p className="registration-list-item" key={item}>
                    {item}
                  </p>
                )
              })}
            </div>
            <div className=" registration-img-wrapper">
              <img className="rounded-[6px] w-full h-full" src={form_bg} alt="Get started using Google Tag Manager" />
            </div>
          </div>
          <div className={styles.rightRoot}>{renderCom}</div>
        </div>
      </main>
    </Layout>
  )
}

export default GA4From
