import React, { useState, useRef } from 'react'
import * as styles from './index.module.less'
import { Form, Input, Select, Checkbox, Button } from 'sensd'
import { PolicyModal } from 'components/Form'

import { industry, countries } from 'components/Form/data'
import { PLATFORMS_OPTIONS, IS_USING_GA4, GA4_VERSION, CUSTOM_EVENT_IN_GA4 } from 'assets/js/constant'
const GAForm = ({ onSubmit }) => {
  const { Item } = Form
  const { Option } = Select
  const [dropdownLoading, setDropdownLoading] = useState(false)
  const [options, setOptions] = useState(countries)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const timer = useRef<any>(null)
  // 地区输入搜索
  const handleSearch = (value) => {
    setDropdownLoading(true)
    clearTimeout(timer.current)
    timer.current = setTimeout(() => {
      setDropdownLoading(false)
      setOptions(countries)
    }, 1500)
  }
  // 地区 列表显示及隐藏
  const handleDropdownVisibleChange = (open) => {
    if (!open) {
      setOptions(countries)
    }
  }
  // 点击隐私协议
  const handleClickPolicy = (e) => {
    e.preventDefault()
    setIsModalVisible(true)
  }
  // 提交表单
  const onFinish = ({ protocol, ...values }: any) => {
    onSubmit?.(values)
  }
  // 校验失败
  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo)
  }
  return (
    <div className={styles.form}>
      <Form name="ga" layout="vertical" onFinish={onFinish} onFinishFailed={onFinishFailed}>
        <Item
          label="Name"
          name="fullName"
          rules={[{ required: true, message: 'This is a required field.' }]}
          validateTrigger="onBlur"
          hideErrorWhenChange
          className="form-item"
        >
          <Input name="name" placeholder="Full Name *" />
        </Item>
        <Item
          className="form-item"
          label="Email"
          name="email"
          rules={[
            {
              type: 'email',
              message: 'Please use a valid email address.',
            },
            {
              required: true,
              message: 'This is a required field.',
            },
          ]}
          validateTrigger="onBlur"
          hideErrorWhenChange
        >
          <Input name="email" placeholder="Email Address *" />
        </Item>
        <Item name="phone" label="Phone" className="form-item">
          <Input placeholder="Phone Number" />
        </Item>
        <Item
          validateTrigger="onBlur"
          name="company"
          label="Company"
          className="form-item"
          rules={[{ required: true, message: 'This is a required field.' }]}
        >
          <Input name="company" placeholder="Company *" />
        </Item>
        <Item
          className="form-item"
          label="Industry"
          name="industry"
          rules={[{ required: true, message: 'This is a required field.' }]}
        >
          <Select statistics={false} className="w-full" placeholder="Select...">
            {industry.map((item) => {
              return (
                <Option value={item} key={item}>
                  {item}
                </Option>
              )
            })}
          </Select>
        </Item>
        <Item
          label="Country"
          className="form-item"
          name="country"
          rules={[{ required: true, message: 'This is a required field.' }]}
        >
          <Select
            statistics={false}
            allowClear
            showDropdownSearch
            dropdownLoading={dropdownLoading}
            onSearch={handleSearch}
            onDropdownVisibleChange={handleDropdownVisibleChange}
            className="w-full"
            placeholder="Select..."
          >
            {options?.map((item) => {
              return (
                <Option value={item} key={item}>
                  {item}
                </Option>
              )
            })}
          </Select>
        </Item>
        <Item
          className="form-item-line"
          name="onlinePlatforms"
          label="What kind of engagement platforms are you running for your customers to visit, browse and act? "
          rules={[{ required: true, message: 'This is a required field.' }]}
        >
          <Select statistics={false} className="w-full" placeholder="Select...">
            {PLATFORMS_OPTIONS.map((item) => {
              return (
                <Option key={item.label} value={item.value}>
                  {item.label}
                </Option>
              )
            })}
          </Select>
        </Item>
        <Item
          className="form-item-line"
          name="usingGa4"
          label="Are your organization  using Google Analytics4(GA4)?"
          rules={[{ required: true, message: 'This is a required field.' }]}
        >
          <Select statistics={false} className="w-full" placeholder="Select...">
            {IS_USING_GA4.map((item) => {
              return (
                <Option key={item.label} value={item.value}>
                  {item.label}
                </Option>
              )
            })}
          </Select>
        </Item>
        <Item
          className="form-item-line"
          name="ga4Version"
          label="What version of GA4 are you using ?"
          rules={[{ required: true, message: 'This is a required field.' }]}
        >
          <Select statistics={false} className="w-full" placeholder="Select...">
            {GA4_VERSION.map((item) => {
              return (
                <Option key={item.label} value={item.value}>
                  {item.label}
                </Option>
              )
            })}
          </Select>
        </Item>
        <Item
          className="form-item-line"
          name="hasCustomEvent"
          label="Have you created and implemented any custom events when using GA4 for the specific analysis needs of your business?"
          rules={[{ required: true, message: 'This is a required field.' }]}
        >
          <Select statistics={false} className="w-full" placeholder="Select...">
            {CUSTOM_EVENT_IN_GA4.map((item) => {
              return (
                <Option key={item.label} value={item.value}>
                  {item.label}
                </Option>
              )
            })}
          </Select>
        </Item>
        <Item
          name="protocol"
          valuePropName="checked"
          rules={[
            {
              validator: (_, value) => (value ? Promise.resolve() : Promise.reject('This field is required.')),
            },
          ]}
          className="form-item-checkbox"
        >
          <Checkbox>
            <span className="text-[#666] font-medium ">
              I agree to Sensors Data's{' '}
              <span onClick={handleClickPolicy} className="text-[#04CB94]">
                Privacy Policy.
              </span>
            </span>
            <PolicyModal isModalVisible={isModalVisible} setIsModalVisible={setIsModalVisible} />
          </Checkbox>
        </Item>
        <Item className="form-submit">
          <Button form="ga" size="large" className="w-full bg-[#00b280]" type="primary" htmlType="submit">
            Submit
          </Button>
        </Item>
      </Form>
    </div>
  )
}

export default GAForm
